/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { UAParser } from 'ua-parser-js'
import axios from '../axios'
import { ENDPOINTS } from '../constants'

class Tracker {
  public user: any
  constructor() {}
  /**
   * @description A method to set the user performing a request to be used in tracking.
   * @param user request user.
   */
  setCurrentRequestUser(user: any) {
    this.user = user
  }
  /**
   * @description A method to track different cases/scenarios in the system and log them to a server.
   * @param trackingCase the desired tracking case, ex: user activity, page navigation, etc.
   * @param action the performed action for a case, ex: user signup, list products, etc.
   * @param otherParams any useful parameters that can be logged with the specified case.
   */
  track(
    trackingCase: string,
    action: string,
    otherParams: Record<string, any>
  ): void {
    try {
      otherParams['userId'] = this.user

      // for future, it is better to send the logs directly to cloudwatch instead of the api to reduce the load on the api
      axios.post(ENDPOINTS.track, {
        tCase: trackingCase,
        action: action,
        ...otherParams,
      })
    } catch (err) {}
  }
  /**
   * @description A method to track different click stream events and actions
   * @param event the desired tracking event, ex: user activity, page navigation, etc.
   * @param otherParams any useful parameters that can be logged with the specified case.
   */
  async trackCs(
    event: string,
    otherParams: Record<string, unknown> = {}
  ): Promise<void> {
    try {
      const payload: Record<string, unknown> = {
        event: event,
        userId: this.user,
        metadata: {
          platform: new UAParser().getBrowser().name,
          os: new UAParser().getOS().name,
          ...otherParams,
        },
      }
      if (otherParams.productUrl) {
        payload.productUrl = otherParams.productUrl
      }
      axios.post(ENDPOINTS.trackCs, payload)
    } catch (err) {
      console.error(
        '🚀 ~ ClickStreamTracker ~ track ~ err',
        (err as Error).message,
        err
      )
    }
  }
}

export default Tracker
