import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, COLORS, FONTS } from '../../constants'

export const styles = makeStyles(theme => ({
  dropDownContainer: {
    marginTop: theme.spacing(5),
    width: '100%',
    margin: 'auto',
    position: 'relative',
  },
  container: {
    position: 'absolute',
    width: '100%',
    background: '#fff',
    boxShadow: '0px 10px 10px 0px rgba(0, 0, 0, 0.1)',
    left: 0,
    right: 0,
    zIndex: 120,
    maxHeight: '75vh',
    minHeight: '75vh',
    overflowY: 'auto',
  },
  shopMenuContainer: {
    display: 'flex',
    width: '90%',
    justifyContent: 'space-between',
    right: 0,
    left: 0,
    zIndex: 1000,
    maxWidth: 1650,
    margin: 'auto',
    maxHeight: '75vh',
  },
  shopMenuColContainer: {
    position: 'relative',
    maxWidth: 330,
  },
  shopMenuLink: {
    textDecoration: 'none',
    color: COLORS.black,
  },
  shopMenuHeading: {
    borderBottom: '1px solid black',
    [`@media (max-width: ${BREAKPOINTS.xl}px)`]: {
      fontSize: '2.5rem',
    },
  },
  shopMenuItemIcon: {
    width: 80,
    height: 80,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: -1,
    borderRadius: 5,
  },
  fashion: {
    right: -67,
    top: 41,
    background: '#FEF2F3',
  },
  home: {
    right: -67,
    top: 0,
    background: '#E7EFED',
  },
  beauty: {
    right: -67,
    top: 20,
    background: '#FDE4E3',
  },
  lifestyle: {
    top: 0,
    right: -56,
    background: '#D1DEE3',
  },
  brandsIcon: {
    top: 35,
    right: -56,
    background: '#FAD9D9',
  },
  header: {
    width: '100%',
    top: -1,
    zIndex: 100,
  },
  headerFull: {
    background: COLORS.white,
  },
  headerMinimized: {
    background: COLORS.whiteStain,
    position: '-webkit-sticky',
    // @ts-ignore
    position: 'sticky',
  },
  listItem: {
    display: 'block',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '0.875rem',
    textDecoration: 'none',
    background: 'transparent',
    border: 'none',
    color: theme.palette.common.black,
    textTransform: 'uppercase',
    letterSpacing: '0.88px',
    cursor: 'pointer',
    textAlign: 'center',
    padding: 0,
    outline: 'none',
    width: 'max-content',
    [`@media (min-width: ${BREAKPOINTS.lg}px) and (max-width: ${BREAKPOINTS.xl}px)`]: {
      fontSize: '0.8rem',
    },
  },
  listItemDisabled: {
    opacity: 0.4,
  },
  sloganBannerWrapper: {
    backgroundColor: COLORS.whiteStain,
  },
  sloganBannerContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 0',
    width: '90%',
    margin: 'auto',
  },
  sloganBanner: {
    display: 'flex',
    flexGrow: 1,
    height: 40,
    padding: '8px 0',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sloganTitle: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      color: theme.palette.common.black,
    },
    [`@media (max-width: ${BREAKPOINTS.sm}px)`]: {
      // width: '95%',
    },
    [`@media (min-width: ${BREAKPOINTS.mdd}px)`]: {
      lineBreak: 'anywhere',
    },
  },
  navigation: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navigationFull: {
    padding: '30px 0',
  },
  navigationMinimized: {
    padding: '15px 0',
  },
  searchButton: {
    order: 3,
    '&:hover': {
      backgroundColor: `${COLORS.white} !important`,
    },
  },

  searchOnColoredBg: {
    order: 3,
    '&:hover': {
      backgroundColor: '#175972 !important',
    },
  },

  /*left nav items */
  navLeft: {
    order: 3,
    position: 'relative',
  },
  yourShoppingBoard: {
    display: 'none',
  },
  brands: {
    display: 'none',
  },
  shop: {
    display: 'none',
  },
  chirpyestLogoLink: {
    display: 'none',
    height: 35,
    width: 35,
    [`@media (min-width: ${BREAKPOINTS.xl}px)`]: {
      display: 'block',
      position: 'absolute',
      top: 7,
      right: 15,
    },
  },
  chirpyestLogo: {
    maxWidth: '100%',
    maxHeight: '100%',
    position: 'relative',
  },
  howItWorks: {
    display: 'none',
  },
  theChirpyest: {
    display: 'none',
  },

  /*center nav items */
  navCenter: {
    order: 2,
    margin: 'auto',
  },
  chirpyestTextLogo: {
    height: '4vw',
    [`@media (max-width: ${BREAKPOINTS.lg}px)`]: {
      height: 47,
    },
  },
  chirpyestTextLogoMinimized: {
    height: 37,
  },
  /*right nav items */
  navRight: {
    order: 1,
  },
  menuButton: {
    background: 'transparent',
    border: 'none',
    padding: 0,
  },
  menuContainer: {
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      display: 'none',
    },
  },
  menuIcon: {
    width: 30,
    height: 14,
  },
  actionLinks: {
    display: 'none',
  },
  signInActions: {
    display: 'none',
  },
  joinContainer: {
    display: 'none',
  },
  shoppingBoardDisabled: {
    fontFamily: FONTS.Graphik.GraphikLight,
    cursor: 'default',
    marginTop: 20,
    pointerEvents: 'none',
    color: COLORS.lightGrey,
  },
  shoppingBoard: {
    color: COLORS.lightGrey,
    cursor: 'pointer',
  },
  textContainer: {
    width: 199,
    fontSize: 12,
    fontFamily: FONTS.Graphik.GraphikLight,
    fontWeight: 300,
  },
  imageContainer: {
    width: 30,
    height: 31,
    marginRight: 15,
  },

  [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
    /*left nav items */
    navLeft: {
      order: 1,
      display: 'flex',
      alignItems: 'center',
      width: '37%',
    },
    howItWorks: {
      display: 'block',
    },
    theChirpyest: {
      display: 'block',
    },
    yourShoppingBoard: {
      display: 'block',
    },
    brands: {
      display: 'block',
      marginRight: 60,
    },
    shop: {
      display: 'block',
      marginRight: 60,
    },

    /*center nav items */
    navCenter: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20%',
    },

    /*right nav items */
    navRight: {
      order: 3,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '37%',
    },
    actionLinks: {
      display: 'block',
    },
    signInActions: {
      display: 'flex',
      marginRight: 60,
      marginLeft: 60,
      ['& > button']: {
        marginRight: 0,
      },
      [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
        marginRight: 18,
        marginLeft: 50,
      },
    },
    join: {
      marginLeft: 60,
      marginRight: 0,
    },
    blog: {
      marginRight: 60,
    },
    joinContainer: {
      display: 'block',
    },
    //this style applies when the user is logged in
    navUserIsLoggedIn: {
      justifyContent: 'space-between',
    },
  },

  bannerBreak: {
    position: 'absolute',
    right: 0,
  },
  bannerItems: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 2,
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      display: 'inline',
    },
  },
  bannerItem: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    [`@media (max-width: ${BREAKPOINTS.mdd}px)`]: {
      padding: '0',
      display: 'inline',
    },
  },
  joinLinkSpan: {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: '10px',
  },
  copiedCaption: {
    color: 'white',
    backgroundColor: '#175972',
    width: 'fit-content',
    opacity: '0.5',
    display: 'flex',
    height: '35px',
    alignItems: 'center',
    padding: '0px 10px',
  },
  leftSubNavContainer: {
    display: 'none',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      position: 'absolute',
      right: 0,
      top: 23,
      display: 'flex',
    },
  },
  rightSubNavContainer: {
    display: 'none',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      position: 'absolute',
      left: 0,
      top: 23,
      display: 'flex',
    },
  },
  subListItemRight: {
    display: 'block',
    fontFamily: FONTS.Graphik.GraphikLight,
    fontSize: '0.75rem',
    textDecoration: 'none',
    background: 'transparent',
    border: 'none',
    color: COLORS.black,
    textTransform: 'uppercase',
    letterSpacing: '0.75px',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '0 20px 0 0',
    outline: 'none',
    width: 'max-content',
    [`@media (min-width: ${BREAKPOINTS.lg}px) and (max-width: ${BREAKPOINTS.xl}px)`]: {
      fontSize: '0.65rem',
    },
  },
  subListItem: {
    display: 'block',
    fontFamily: FONTS.Graphik.GraphikLight,
    fontSize: '0.75rem',
    textDecoration: 'none',
    background: 'transparent',
    border: 'none',
    color: COLORS.black,
    textTransform: 'uppercase',
    letterSpacing: '0.75px',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '0 0 0 20px',
    outline: 'none',
    width: 'max-content',
    [`@media (min-width: ${BREAKPOINTS.lg}px) and (max-width: ${BREAKPOINTS.xl}px)`]: {
      fontSize: '0.65rem',
    },
  },
  coloredHeader: {
    backgroundColor: '#175972',
  },
  whiteText: {
    color: COLORS.white,
  },
  whiteLogo: {
    filter: 'invert(100%)',
  },
  fullWidth: {
    [`@media (min-width: ${BREAKPOINTS.lg}px) and (max-width: ${BREAKPOINTS.xl}px)`]: {
      width: '100%',
    },
  },
  logo: {
    width: '26px',
    height: '28px',
    marginRight: '10px',
  },
  bannerMainText: {
    fontSize: 14,
    lineHeight: 1,
  },
  bannerText: {
    margin: 0,
    fontSize: 10,
    color: COLORS.lightGrey,
  },
  installButton: {
    borderRadius: 20,
    border: 'none',
    padding: '4px 10px',
    backgroundColor: COLORS.lightTeal,
  },
}))
