import axios from 'axios'
import { baseURL } from './constants'
import Auth from '@aws-amplify/auth'
import * as moment from 'moment'
import { setItemToLocalStorage } from '../utils/setLocalStorage'

const instance = axios.create({
  baseURL: baseURL,
})

instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN'
instance.defaults.headers.post['Content-Type'] = 'application/json'
instance.defaults.headers.common['Referral-Id'] = ''
instance.defaults.headers.common['X-Source'] = 'WEBSITE'

instance.interceptors.request.use(function(config) {
  return Auth.currentSession()
    .then(session => {
      config.headers.Authorization = `Bearer ${session
        .getAccessToken()
        .getJwtToken()}`

      const referralIdFallBackJSON =
        window.localStorage.getItem('referralId') || '{}'
      const referralIdFallBack = JSON.parse(referralIdFallBackJSON)
      if (Object.keys(referralIdFallBack)?.length) {
        const { id, time } = referralIdFallBack
        const referralIdAge = moment().diff(moment(time), 'days')
        if (referralIdAge <= 3) {
          config.headers['Referral-Id'] = id
        }
      }

      return Promise.resolve(config)
    })
    .catch(() => {
      return Promise.resolve(config)
    })
})

instance.interceptors.response.use(
  (response: any) => {
    let url = response.config.url.split('/')
    url.length == 8 ? url.splice(6, 1) : url.splice(3, 1)
    url = url.join('/')
    if (
      url === 'api/v1/users/profile' ||
      url.includes('api/v1/users/profile')
    ) {
      setItemToLocalStorage(
        'profile',
        JSON.stringify({
          firstName: response?.data?.data?.name.split(' ')[0],
          lastName: response?.data?.data?.name.split(' ')[1],
          username: response?.data?.data?.username,
          email: response?.data?.data?.email,
        })
      )
    }

    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default instance
