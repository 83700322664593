import React, { createContext, useReducer, useContext } from 'react'
import { generateAnonId } from '../utils/randomIdGenerator'
import { tracker } from './systemLogs'
import { setItemToLocalStorage } from '../utils/setLocalStorage'

interface IState {
  [key: string]: string
}

interface IContextProps {
  state: IState
  dispatch: ({ type }: { type: string }) => void
}

const Context = createContext({} as IContextProps)

const isBrowser = () => typeof window !== 'undefined'

export function AppStateProvider({ reducer, initialState, children }: any) {
  if (isBrowser()) {
    setItemToLocalStorage('userId', generateAnonId())
  }
  const value = useReducer(reducer, initialState)
  let userId = value[0].userId
  if (value[0].userId === null) {
    userId = isBrowser() && window.localStorage.getItem('userId')
  }
  tracker.setCurrentRequestUser(userId)
  return <Context.Provider value={value}>{children}</Context.Provider>
}

export function useAppState() {
  return useContext(Context)
}
