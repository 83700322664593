import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import CssBaseline from '@material-ui/core/CssBaseline'
import CHRBanner from './src/components/header/Banner'
import appReducer, { initialState } from './src/appReducer'
import { AppStateProvider, useAppState } from './src/appState'
import Auth from '@aws-amplify/auth'
import axios from './src/axios'
import { ENDPOINTS, EXTENSION_ID, baseURL, ROUTES } from './src/constants'
import { userRemovedExtension } from './utils/userRemovedExtension'
import { sendExtensionMessage } from './utils/extensionHelper'
import { ToastContainer } from 'react-toastify'
import ErrorBoundary from './src/components/ErrorBoundary'
import { ThemeProvider } from '@material-ui/styles'
import theme from './src/gatsby-theme-material-ui-top-layout/theme'
import 'react-toastify/dist/ReactToastify.css'
import Spinner from './src/components/spinner'
import { TrackingCase } from './utils/trackingCases'
import { tracker } from './src/systemLogs'
import { setItemToLocalStorage } from './utils/setLocalStorage'

const isRedirectFromSocial = path => {
  const url = new URL(path)
  const code = url.searchParams.get('code')
  const state = url.searchParams.get('state')
  return code && state
}
const SessionCheck = ({ children }) => {
  const [, dispatch] = useAppState()

  //comment
  useEffect(() => {
    const [param] = window && window.location.search.slice(1).split('&')
    userRemovedExtension(param)
    const getSettings = async () => {
      const response = await axios.get(`${baseURL}${ENDPOINTS.settings}`)
      const siteSettings = response.data.data.find(
        a => a.name === 'website_setting'
      )?.setting_value
      dispatch({ type: 'UPDATE_SETTINGS', settings: siteSettings })
    }
    const checkAuth = async () => {
      let auth
      let isAuthenticated = false

      try {
        auth = await Auth.currentSession()
        isAuthenticated = auth.isValid()
      } catch (err) {
        isAuthenticated = false
      }

      if (isAuthenticated) {
        try {
          let userId = auth.getIdToken().payload['custom:user_id']
          let signUpViaSocial = false
          // when user sign up via social network
          // user get a jwt token but server does not have record for the user
          // and when that happens userId will be undefined
          // so we can request server to create account then recreate jwt with user id within it
          if (!userId) {
            signUpViaSocial = true
            try {
              await axios.post(ENDPOINTS.socialSignUp)
            } catch (err) { }

            const newUser = await Auth.currentAuthenticatedUser({
              bypassCache: true,
            })
            auth = await Auth.currentSession()
            userId = Number(newUser.attributes['custom:user_id'])
            let payload = auth.getIdToken()
            payload['custom:user_id'] = userId
            payload['custom:user_role'] = newUser.attributes['custom:user_role']
          }

          const isApp = getIsApp();
          const {
            data: { data },
          } = await axios.get(ENDPOINTS.user.replace(':id', userId) + (isApp ? '?safariApp=true' : ''))

          const userProfile = {
            email: data.email,
            userName: data.username,
            fullName: data.name,
            imageKey: data.image,
            imageUrl: '',
            isPro: data.isPro,
            isCreator: data.isCreator,
            isReferralBonus: data.isReferralBonus,
            userDoublePayment: data.userDoublePayment,
            financialData: data.financialData || {
              pending: '0',
              lastClosedOut: '0',
              receivableMilestone: '0',
            },
            loginProvider: data.loginProvider,
          }

          if (data.image) {
            const url = `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${data.image}`
            userProfile.imageUrl = url
          }

          /**
           * when user click on activate cash back on a brand website
           * and the extension is not signed in, user will be redirected to the website
           * and this function will be called which send the current session to the extension.
           *
           * the extension validate the session and save store it then it tries to activate cash back
           * on the brand that we visited before, at that step will again check if user is signed in.
           * when that function fails it will redirect user to website again to get a session
           * which will create a loop where website is opened and closed constantly.
           *
           * this checks the last time we shared the session and throttle it to break the loop
           */
          const lastMessageEpoch = Number(
            window.localStorage.getItem('lastExtensionSessionDate') || 0
          )
          const nowEpoch = new Date().valueOf()
          const timeBetweenMessages = 10 * 1000
          if (nowEpoch - lastMessageEpoch > timeBetweenMessages) {
            sendExtensionMessage({ session: auth })
            setItemToLocalStorage('lastExtensionSessionDate', nowEpoch)

            if (window.chrome?.runtime?.sendMessage) {
              window.chrome.runtime.sendMessage(
                EXTENSION_ID,
                { session: auth },
                _response => { }
              )
            }

            const urlParams = new URLSearchParams(window.location.search)
            const signInFromExtension = urlParams.get('signInFromExtension')
            if (signInFromExtension) window.close()
          }

          dispatch({ type: 'AUTH_CHANGE', auth, userProfile, userId })
          if (signUpViaSocial && !userProfile.userName)
            navigate(ROUTES.personalInfo)
        } catch (err) {
          if (err) {
            tracker.track(TrackingCase.UserTracking, `Check Auth`, {
              errorMessage: err.message,
            })
            console.log(err)
            alert('read console error message')
            navigate(ROUTES.home)
            window.localStorage.removeItem('movedItems')
            window.localStorage.removeItem('ig_token')
            window.localStorage.removeItem('user_id')
            window.localStorage.removeItem('username')
            Auth.signOut()
          }
        }
      }

      await getSettings() // TODO: handle error
    }
    checkAuth()
  }, [dispatch])

  if (typeof window === 'undefined') return <div></div>
  if (isRedirectFromSocial(window.location.href)) {
    return (
      <span style={{ minHeight: 280 }}>
        <Spinner />
      </span>
    )
  }

  return <React.Fragment>{children}</React.Fragment>
}
const getIsApp = () => {
  const isApp = typeof window !== 'undefined' &&
    (window.location.pathname.includes('/app') ||
      window.localStorage.getItem('howItWorksStatus'))

  return isApp
}
const wrapRootElement = ({ element }) => {
  const isCashback =
    typeof window !== 'undefined' &&
    window.location.pathname.includes('/cashback')
  const isApp = getIsApp()
  const isHeaderHidden = isCashback || isApp
  return (
    <ErrorBoundary>
      <AppStateProvider reducer={appReducer} initialState={initialState}>
        <SessionCheck>
          <div>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {!isHeaderHidden && <CHRBanner />}
              {element}
            </ThemeProvider>
          </div>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </SessionCheck>
      </AppStateProvider>
    </ErrorBoundary>
  )
}

export default wrapRootElement
